<template>
  <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item
      color="orange lighten-2"
      icon="mdi-account-multiple"
      fill-dot
      class="mt-10"
    >
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          Gain de temps
        </v-card-title>
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Avec son service de réservation en ligne, sa communication
            automatisée par email et sa gestion administrative, monplanning
            est facile à utiliser et flexible. De quoi vous rendre toute
            l'énergie et le temps nécessaire à votre croissance personnelle et
            professionnelle.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="deep-orange lighten-2" icon="mdi-web" fill-dot>
      <v-card color="deep-orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          Activité
        </v-card-title>
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Comptez sur monplanning pour vous donner une vue claire de votre
            planning, abonnements, clients, réservations et paiements. Partez
            l'esprit tranquille et profitez de votre métier.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item color="orange lighten-2" icon="mdi-tag" fill-dot>
      <v-card color="orange lighten-2" dark>
        <v-card-title class="pa-2 font-weight-medium">
          Réservation en ligne</v-card-title
        >
        <v-card-text class="white text-body-1 text--primary">
          <div class="pt-4">
            Laissez vos clients réserver les cours directement sur
            monplanning. Plus besoin de vous contacter constamment pour un
            changement de réservation ou un désistement.
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>


<script>
export default {
  name: "Timeline",
  components: {},
  data() {
    return {};
  },
};
</script>