<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col class="mt-10 text-center font-weight-medium">
          <div class="text-h3 font-weight-medium">
            Nous recherchons tous la même chose,
          </div>
          <div class="text-h3 font-weight-medium primary--text">
            la sérénité et l'équilibre entre vie professionnelle et vie
            personnelle.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-10" justify="center">
        <v-col
          ><v-img
            src="/static/sport/multi-sports.png"
            class="rounded-lg"
            max-height="300"
            contain
          ></v-img
        ></v-col>
      </v-row>
      <v-row justify="center" class="text-h5 text-center mt-16">
        <v-col>
          <div class="">
            Que vous soyez dans un salle de muscu, dans un studio, sur un ring,
            dans une piscine, dans une salle de sport, sur le parquet, ..
          </div>
          <div class="primary--text font-weight-medium">
            monplanning s'adapte à vous
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center" class="ma-0 pa-0" no-gutters>
        <v-col sm="12" md="8" class="ma-0 pa-0">
          <SportsTimeline />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SportsTimeline from "@/components/Public/SportsTimeline";

export default {
  components: {
    SportsTimeline,
  },
};
</script>